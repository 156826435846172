import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../../css/corporate.css";
import { Routes } from "../../routes";
import syncImg from "../../assets/img/sync.svg";
import productsImg from "../../assets/img/products.svg";
import socialImg from "../../assets/img/social.svg";
import { parsedUser } from "../../common/GetCurrentUser";

//Translation
import { useTranslation } from "react-i18next";
import Header from "./Header";
import Footer from "./Footer";

const Corporate = () => {
  const history = useHistory();
  const user = parsedUser();

  const { t } = useTranslation("global");

  return (
    <div className="corporate">
      <Header />

      <header className="masthead" id="home">
        <div className="container px-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              <div className="mb-5 mb-lg-0 text-center text-lg-start">
                <h1 className="display-1 lh-1 mb-3">
                  {t("corporate.your_office")}
                </h1>
                <p className="lead fw-normal text-muted mb-5">
                  {t("corporate.manage_simple_way")}
                </p>
                <div className="d-flex flex-column flex-lg-row align-items-center">
                  <a className="btn btn-primary" href={Routes.LogIn.path}>
                    {t("corporate.shall_begin")}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="masthead-device-mockup">
                <img src={syncImg}></img>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- Quote/testimonial aside--> */}
      <aside className="text-center bg-gradient-primary-to-secondary">
        <div className="container px-5">
          <div className="row gx-5 justify-content-center">
            <div className="col-xl-8">
              <div className="h2 fs-1 text-white mb-4">
                "{t("corporate.manage_office")}"
              </div>
              {/* <img src="assets/img/tnw-logo.svg" alt="..." style="height: 3rem" /> */}
            </div>
          </div>
        </div>
      </aside>
      {/* <!-- App features section--> */}
      <section id="features">
        <div className="container px-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-8 order-lg-1 mb-5 mb-lg-0">
              <div className="container-fluid px-5">
                <div className="row gx-5">
                  <div className="col-md-6 mb-5">
                    {/* <!-- Feature item--> */}
                    <div className="text-start">
                      <i className="bi-phone icon-feature text-gradient d-block mb-3"></i>
                      <h3 className="font-alt">{t("generic.invoicing")}</h3>
                      <p className="text-muted mb-0">
                        {t("corporate.invoicing.content")}
                      </p>
                      <p>
                        <button
                          className="btn btn-primary btn-sm mt-2"
                          onClick={() => {
                            history.push(Routes.Invoicing.path);
                          }}
                        >
                          Leer más
                        </button>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    {/* <!-- Feature item--> */}
                    <div className="text-start">
                      <i className="bi-camera icon-feature text-gradient d-block mb-3"></i>
                      <h3 className="font-alt">{t("titles.expenses")}</h3>
                      <p className="text-muted mb-0">
                        {t("corporate.expenses.content")}
                      </p>
                      <p>
                        <button className="btn btn-primary btn-sm mt-2">
                          Leer más
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-5 mb-md-0">
                    {/* <!-- Feature item--> */}
                    <div className="text-start">
                      <i className="bi-gift icon-feature text-gradient d-block mb-3"></i>
                      <h3 className="font-alt">{t("generic.comunication")}</h3>
                      <p className="text-muted mb-0">
                        {t("corporate.comunication.content")}
                      </p>
                      <p>
                        <button className="btn btn-primary btn-sm mt-2">
                          Leer más
                        </button>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/* <!-- Feature item--> */}
                    <div className="text-start">
                      <i className="bi-patch-check icon-feature text-gradient d-block mb-3"></i>
                      <h3 className="font-alt">{t("titles.payroll")}</h3>
                      <p className="text-muted mb-0">
                        {t("corporate.payroll.content")}
                      </p>
                      <p>
                        <button className="btn btn-primary btn-sm mt-2">
                          Leer más
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 order-lg-0">
              {/* <!-- Features section device mockup--> */}
              <div className="features-device-mockup">
                <img src={productsImg} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Basic features section--> */}
      <section className="bg-light d-none">
        <div className="container px-5">
          <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
            <div className="col-12 col-lg-5">
              <h2 className="display-4 lh-1 mb-4">
                Enter a new age of web design
              </h2>
              <p className="lead fw-normal text-muted mb-5 mb-lg-0">
                This section is perfect for featuring some information about
                your application, why it was built, the problem it solves, or
                anything else! There's plenty of space for text here, so don't
                worry about writing too much.
              </p>
            </div>
            <div className="col-sm-8 col-md-6">
              <div className="px-5 px-sm-0">
                <img
                  className="img-fluid rounded-circle"
                  src="https://source.unsplash.com/u8Jn2rzYIps/900x900"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Call to action section--> */}
      <section className="cta d-none ">
        <div className="cta-content">
          <div className="container px-5">
            <h2 className="text-white display-1 lh-1 mb-4">
              {t("corporate.stop_waiting")}.
              <br />
              {t("corporate.start_building")}.
            </h2>
            <a
              className="btn btn-outline-light py-3 px-4 rounded-pill"
              href="https://startbootstrap.com/theme/new-age"
              rel="noreferrer"
              target="_blank"
            >
              {t("corporate.free_download")}.
            </a>
          </div>
        </div>
      </section>
      {/* WHY PLEELO */}
      <section className="bg-gradient-primary-to-secondary" id="about">
        <div className="container px-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              <h2 className="text-white">¿Por qué Pleelo?</h2>
              <p className="text-white">
                Pleelo es una solución que nació de nuestra propia necesidad en Valego Consulting, SRL de gestionar nuestra empresa de forma sencilla y eficiente. Sabemos que hay muchas otras opciones en el mercado, pero ninguna nos convenció por su complejidad o falta de adaptación al flujo de trabajo de una empresa moderna.
              </p>
              <p className="text-white mt-4">
                Con Pleelo, puedes gestionar todo lo que necesitas para tu negocio: facturación, presupuestos, gastos, nóminas, comunicación y más. Todo desde una sola plataforma, fácil de usar e intuitiva. Nuestro objetivo es que puedas dedicar menos tiempo a la administración y más tiempo a lo que realmente importa: tu negocio.
              </p>
            </div>
            <div className="col-lg-6">
              <div className="masthead-device-mockup">
                <img src={socialImg}></img>
              </div>
            </div>
          </div>
        </div>
      </section>
  
      <Footer></Footer>
    </div>
  );
};

export default Corporate;
